import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, Button, Label, Select, } from '@realm-kf/halcyon-components-react';
import { _t } from '@hip/translations';
import { dataTestId } from '@hip/test-attributes';
import { actions, getPersonalDetailsFormState, getIsPersonalDetailsFormValid, } from '../../core/personalDetails';
import { getQuestionsGroup } from '../../core/questions';
import { IntlPhoneInput } from '../../components/intlPhoneInput/intlPhoneInput';
import { getIntlPhoneInputLocalisation } from '../../core/utils';
import { validateFirstName, validateLastName, validateEmailAddress, validateIntlPhoneNumber, validateQuestions, } from './validations';
import styles from './personalDetailsForm.css';
var PersonalDetailsForm = function (_a) {
    var onSubmit = _a.onSubmit;
    var dispatch = useDispatch();
    var formState = useSelector(getPersonalDetailsFormState);
    var isFormValid = useSelector(getIsPersonalDetailsFormValid);
    var question = useSelector(getQuestionsGroup);
    var _b = useSelector(getIntlPhoneInputLocalisation), preselectedCountry = _b.preselectedCountry, quickCountries = _b.quickCountries;
    var isQuestionsRequired = Boolean(question === null || question === void 0 ? void 0 : question.required);
    useEffect(function () {
        if (!isQuestionsRequired) {
            dispatch(actions.handleInput({
                field: 'questions',
                input: {
                    error: null,
                    value: '',
                    isValid: true,
                },
            }));
        }
    }, []);
    var submitHandler = function (e) {
        e.preventDefault();
        onSubmit();
    };
    var createHandleInput = function (field, validationFn) { return function (value) {
        var _a = validationFn(value), error = _a.error, isValid = _a.isValid;
        dispatch(actions.handleInput({ field: field, input: { error: error, isValid: isValid, value: value } }));
    }; };
    var firstNameHandler = createHandleInput('firstName', validateFirstName);
    var lastNameHandler = createHandleInput('lastName', validateLastName);
    var emailAddressHandler = createHandleInput('emailAddress', validateEmailAddress);
    var intlPhoneHandler = createHandleInput('intlPhoneNumber', validateIntlPhoneNumber);
    var questionsHandler = createHandleInput('questions', validateQuestions(isQuestionsRequired));
    var handleDirty = function (field) {
        dispatch(actions.setFieldDirty({ field: field }));
    };
    return (React.createElement("form", { onSubmit: submitHandler },
        React.createElement(Input, { classes: "hlcn-u-display-block hlcn-u-mb-medium", labelText: _t('appointment_booking.steps.contacts.form.fields.firstName.label'), error: formState.firstName.isDirty ? formState.firstName.error : '', onChange: function (event) { return firstNameHandler(event.target.value); }, value: formState.firstName.value, onBlur: function () { return handleDirty('firstName'); }, "data-testid": dataTestId.ab.personalDetails.firstNameInput }),
        React.createElement(Input, { classes: "hlcn-u-display-block hlcn-u-mb-medium", labelText: _t('appointment_booking.steps.contacts.form.fields.lastName.label'), error: formState.lastName.isDirty ? formState.lastName.error : '', onChange: function (event) { return lastNameHandler(event.target.value); }, value: formState.lastName.value, onBlur: function () { return handleDirty('lastName'); }, "data-testid": dataTestId.ab.personalDetails.lastNameInput }),
        React.createElement(Input, { classes: "hlcn-u-display-block hlcn-u-mb-medium", labelText: _t('appointment_booking.steps.contacts.form.fields.emailAddress.label'), type: "email", error: formState.emailAddress.isDirty ? formState.emailAddress.error : '', onChange: function (event) { return emailAddressHandler(event.target.value); }, value: formState.emailAddress.value, onBlur: function () { return handleDirty('emailAddress'); }, "data-testid": dataTestId.ab.personalDetails.emailAddressInput }),
        React.createElement(Label, { classes: "hlcn-label__text hlcn-u-mt-small", labelText: _t('appointment_booking.steps.contacts.form.fields.intlPhoneNumber.label') }),
        React.createElement(IntlPhoneInput, { onChange: intlPhoneHandler, error: formState.intlPhoneNumber.isDirty
                ? formState.intlPhoneNumber.error
                : '', onBlur: function () { return handleDirty('intlPhoneNumber'); }, preselectedCountry: preselectedCountry, placeholder: _t('appointment_booking.steps.contacts.form.fields.intlPhoneNumber.phoneNumber.placeholder'), quickCountries: quickCountries }),
        question && (React.createElement("div", { "data-testid": 'questions-select' },
            React.createElement(Select, { onBlur: function () { return handleDirty('questions'); }, required: isQuestionsRequired, error: formState.questions.isDirty ? formState.questions.error : '', 
                //@ts-ignore
                onChange: function (e) { return questionsHandler(e.target.value); }, classes: "hlcn-label__text hlcn-u-mt-small", labelText: "" + question.name + (isQuestionsRequired
                    ? ''
                    : " (" + _t('appointment_booking.steps.contacts.form.fields.questions.optional') + ")"), options: question.options.map(function (o) { return ({
                    value: o.name,
                    label: o.name,
                }); }) }))),
        React.createElement(Button, { classes: styles.HiddenButton, variant: "form", disabled: !isFormValid }, _t('appointment_booking.steps.contacts.form.submit'))));
};
export { PersonalDetailsForm };
