import { createSelector } from 'reselect';
export var getRoot = function (state) {
    return state.questions;
};
export var getIsQuestionsLoading = createSelector(getRoot, function (_a) {
    var isLoading = _a.isLoading;
    return isLoading;
});
export var getQuestionsGroup = createSelector(getRoot, function (_a) {
    var questionsGroup = _a.questionsGroup;
    return Array.isArray(questionsGroup) && questionsGroup[0];
});
export var getQuestionsGroupId = createSelector(getRoot, function (_a) {
    var questionsGroupId = _a.questionsGroupId;
    return questionsGroupId;
});
export var getQuestionId = createSelector(getQuestionsGroup, function (questionsGroup) { return questionsGroup === null || questionsGroup === void 0 ? void 0 : questionsGroup.id; });
