import { createSelector } from 'reselect';
import { JrniServiceCategory } from './interfaces';
var getRoot = function (_a) {
    var utils = _a.utils;
    return utils;
};
export var getConfig = createSelector(getRoot, function (_a) {
    var config = _a.config;
    return config;
});
export var getCategory = createSelector(getRoot, function (_a) {
    var category = _a.category;
    return category;
});
export var getIsAppReady = createSelector(getRoot, function (_a) {
    var isAppReady = _a.isAppReady;
    return isAppReady;
});
export var getJrniBaseUrl = createSelector(getConfig, function (_a) {
    var jrni = _a.jrni;
    return jrni === null || jrni === void 0 ? void 0 : jrni.baseUrl;
});
export var getJrniGrandParentId = createSelector(getConfig, function (_a) {
    var jrni = _a.jrni;
    return jrni === null || jrni === void 0 ? void 0 : jrni.grandParentId;
});
export var getJrniAppId = createSelector(getConfig, function (_a) {
    var jrni = _a.jrni;
    return jrni === null || jrni === void 0 ? void 0 : jrni.appId;
});
export var getJrniOnlineCategoryId = createSelector(getConfig, function (_a) {
    var jrni = _a.jrni;
    return jrni === null || jrni === void 0 ? void 0 : jrni.category.onlineCategoryId;
});
export var getJrniInStoreCategoryId = createSelector(getConfig, function (_a) {
    var jrni = _a.jrni;
    return jrni === null || jrni === void 0 ? void 0 : jrni.category.inStoreCategoryId;
});
export var getJrniEnergyAssessmentCategoryId = createSelector(getConfig, function (_a) {
    var jrni = _a.jrni;
    return jrni === null || jrni === void 0 ? void 0 : jrni.category.energyAssessmentCategoryId;
});
export var getJrniEnergyAssessmentInStoreCategoryId = createSelector(getConfig, function (_a) {
    var _b;
    var jrni = _a.jrni;
    return (_b = jrni === null || jrni === void 0 ? void 0 : jrni.category) === null || _b === void 0 ? void 0 : _b.energyAssessmentInStoreCategoryId;
});
export var getJrniGardenDesignCategoryId = createSelector(getConfig, function (_a) {
    var _b;
    var jrni = _a.jrni;
    return (_b = jrni === null || jrni === void 0 ? void 0 : jrni.category) === null || _b === void 0 ? void 0 : _b.gardenDesignCategoryId;
});
export var getJrniWindowsAndDoorsInstoreCategoryId = createSelector(getConfig, function (_a) {
    var _b;
    var jrni = _a.jrni;
    return (_b = jrni === null || jrni === void 0 ? void 0 : jrni.category) === null || _b === void 0 ? void 0 : _b.windowsAndDoorsCategoryId;
});
export var getJrniCategoryId = createSelector(getCategory, function (state) { return state; }, function (category, state) {
    switch (category) {
        case JrniServiceCategory.Online:
            return getJrniOnlineCategoryId(state);
        case JrniServiceCategory.InStore:
            return getJrniInStoreCategoryId(state);
        case JrniServiceCategory.EnergyAssessment:
            return getJrniEnergyAssessmentCategoryId(state);
        case JrniServiceCategory.EnergyAssessmentInStore:
            return getJrniEnergyAssessmentInStoreCategoryId(state);
        case JrniServiceCategory.GardenDesignInstore:
            return getJrniGardenDesignCategoryId(state);
        case JrniServiceCategory.WindowsAndDoors:
            // TODO: Change
            return getJrniWindowsAndDoorsInstoreCategoryId(state);
        default:
            return getJrniInStoreCategoryId(state);
    }
});
export var getJrniVirtualHubID = createSelector(getConfig, function (_a) {
    var jrni = _a.jrni;
    return jrni === null || jrni === void 0 ? void 0 : jrni.virtualHubID;
});
export var getJrniCallCentreID = createSelector(getConfig, function (_a) {
    var jrni = _a.jrni;
    return jrni === null || jrni === void 0 ? void 0 : jrni.callCentreID;
});
export var getOPCO = createSelector(getRoot, function (_a) {
    var opco = _a.opco;
    return opco;
});
export var getWoosmapApiKey = createSelector(getConfig, function (config) { return config === null || config === void 0 ? void 0 : config.woosmapApiKey; });
export var getHostUrl = createSelector(getConfig, function (_a) {
    var hostUrl = _a.hostUrl;
    return hostUrl;
});
export var getAnalyticsConfig = createSelector(getConfig, function (config) { return config === null || config === void 0 ? void 0 : config.analytics; });
export var getIntlPhoneInputLocalisation = createSelector(getConfig, function (_a) {
    var localisation = _a.localisation;
    return localisation === null || localisation === void 0 ? void 0 : localisation.intlPhoneInput;
});
