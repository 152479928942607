export var generateQuestionsPayload = function (questionId, questionAnswerId) {
    return questionId && questionAnswerId
        ? {
            questions: [
                {
                    id: questionId,
                    answer: questionAnswerId,
                },
            ],
        }
        : null;
};
