var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useState, useCallback, useRef, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { allCountries } from 'country-telephone-data';
import { Text, IconArrowDown, FormError, } from '@realm-kf/halcyon-components-react';
import { dataTestId } from '@hip/test-attributes';
import { IntlPhoneFlagIcon } from './components/intlPhoneFlagIcon/intlPhoneFlagIcon';
import { IntlPhoneOption } from './components/intlPhoneOption/intlPhoneOption';
import { getInitialCountry } from './helpers';
import styles from './intlPhoneInput.css';
var IntlPhoneInput = function (_a) {
    var onChange = _a.onChange, onBlur = _a.onBlur, preselectedCountry = _a.preselectedCountry, _b = _a.initialPhoneNumber, initialPhoneNumber = _b === void 0 ? '' : _b, placeholder = _a.placeholder, error = _a.error, includeCountries = _a.includeCountries, excludeCountries = _a.excludeCountries, quickCountries = _a.quickCountries;
    var countries = useMemo(function () {
        if (excludeCountries)
            return allCountries.filter(function (country) { return !excludeCountries.includes(country.iso2); });
        if (includeCountries)
            return allCountries.filter(function (country) {
                return includeCountries.includes(country.iso2);
            });
        return allCountries;
    }, [includeCountries, excludeCountries, allCountries]);
    var quickCountriesArray = useMemo(function () {
        return quickCountries
            ? allCountries.filter(function (_a) {
                var iso2 = _a.iso2;
                return quickCountries.includes(iso2);
            })
            : [];
    }, [quickCountries, allCountries]);
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState({
        country: getInitialCountry({ preselectedCountry: preselectedCountry, quickCountries: quickCountries }),
        phoneNumber: initialPhoneNumber,
    }), state = _d[0], setState = _d[1];
    var dropdownRef = useRef(null);
    var optionRef = useRef(null);
    var closeListener = useCallback(function (e) {
        var isDropdown = e.target.parentNode === dropdownRef.current;
        if (!isDropdown)
            setOpen(false);
    }, []);
    useEffect(function () {
        onChange(state);
    }, [state]);
    useEffect(function () {
        if (open) {
            document.addEventListener('click', closeListener);
            optionRef.current.focus();
        }
        else {
            document.removeEventListener('click', closeListener);
        }
    }, [open]);
    var countrySelectHandler = function (country) {
        setState(__assign(__assign({}, state), { country: country }));
        setOpen(false);
    };
    var keyDownHandler = function (country) { return function (e) {
        var KEYCODE_DOWN = 40;
        var KEYCODE_UP = 38;
        var KEYCODE_ENTER = 13;
        var KEYCODE_ESC = 27;
        switch (e.keyCode) {
            case KEYCODE_UP: {
                var prevItem = optionRef.current.previousSibling;
                if (prevItem) {
                    prevItem.focus();
                    optionRef.current = prevItem;
                }
                break;
            }
            case KEYCODE_DOWN: {
                var nextItem = optionRef.current.nextSibling;
                if (nextItem) {
                    nextItem.focus();
                    optionRef.current = nextItem;
                }
                break;
            }
            case KEYCODE_ENTER:
                countrySelectHandler(country);
                break;
            case KEYCODE_ESC:
                setOpen(false);
                break;
            default:
                return;
        }
    }; };
    var quickListRefCallback = function (iso2) { return function (node) {
        if (state.country.iso2 === iso2)
            optionRef.current = node;
    }; };
    var mainListRefCallback = function (iso2) { return function (node) {
        var isIsoInQuicklist = quickCountries === null || quickCountries === void 0 ? void 0 : quickCountries.includes(iso2);
        if (isIsoInQuicklist)
            return;
        if (state.country.iso2 === iso2)
            optionRef.current = node;
    }; };
    var countriesOptions = useMemo(function () { return __spreadArrays(quickCountriesArray.map(function (country, i) { return (React.createElement(IntlPhoneOption, { country: country, onClick: countrySelectHandler, onKeyDown: keyDownHandler, refCallback: quickListRefCallback, showSeparator: i === quickCountries.length - 1, key: "quick_" + country.iso2 })); }), countries.map(function (country) { return (React.createElement(IntlPhoneOption, { country: country, onClick: countrySelectHandler, onKeyDown: keyDownHandler, refCallback: mainListRefCallback, key: country.iso2 })); })); }, [quickCountriesArray, countries]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "hlcn-u-align-top hlcn-u-mb-medium", "data-testid": dataTestId.ab.personalDetails.phone.phoneField },
            React.createElement("div", { "data-testid": dataTestId.ab.personalDetails.phone.countryCode, className: classnames('hlcn-input', 'hlcn-u-align-space-between', 'hlcn-u-align-center', styles.IntlPhoneInputValue), onClick: function () { return setOpen(!open); } },
                React.createElement("div", { className: "hlcn-u-align-center" },
                    React.createElement(IntlPhoneFlagIcon, { iso2: state.country.iso2, classes: "hlcn-u-mr-small" }),
                    React.createElement(Text, null,
                        "+",
                        state.country.dialCode)),
                React.createElement(IconArrowDown, null)),
            React.createElement("input", { "data-testid": dataTestId.ab.personalDetails.phone.phoneNumberInput, className: classnames('hlcn-input', styles.IntlPhoneNumberInput), onChange: function (e) { return setState(__assign(__assign({}, state), { phoneNumber: e.target.value })); }, onBlur: onBlur, value: state.phoneNumber, placeholder: placeholder })),
        React.createElement("div", { className: "hlcn-u-pos-relative" },
            React.createElement("ul", { ref: dropdownRef, "data-testid": dataTestId.ab.personalDetails.phone.countryCodesDropDown, className: classnames('hlcn-u-bgc-light', 'hlcn-u-border-light', styles.IntlPhoneInputDropdown, !open && 'hlcn-u-is-hidden') }, countriesOptions)),
        error && React.createElement(FormError, null, error)));
};
export { IntlPhoneInput };
