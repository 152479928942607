import { createAction } from '@hip/helpers';
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["SET_QUESTIONS_GROUP_ID"] = "@hip/appointment-booking/questions/SET_QUESTIONS_GROUP_ID";
    ActionTypes["FETCH_QUESTIONS_GROUP"] = "@hip/appointment-booking/questions/FETCH_QUESTIONS_GROUP";
    ActionTypes["FETCH_QUESTIONS_GROUP_SUCCESS"] = "@hip/appointment-booking/questions/FETCH_QUESTIONS_GROUP_SUCCESS";
    ActionTypes["FETCH_QUESTIONS_GROUP_FAILURE"] = "@hip/appointment-booking/questions/FETCH_QUESTIONS_GROUP_FAILURE";
    ActionTypes["RESET_QUESTION"] = "@hip/appointment-booking/questions/RESET_QUESTIONS";
})(ActionTypes || (ActionTypes = {}));
export var actions = {
    resetQuestions: function () { return createAction(ActionTypes.RESET_QUESTION); },
    setQuestionsGroupId: function (id) {
        return createAction(ActionTypes.SET_QUESTIONS_GROUP_ID, { id: id });
    },
    fetchQuestionsGroup: function (payload) {
        return createAction(ActionTypes.FETCH_QUESTIONS_GROUP, payload);
    },
    fetchQuestionsGroupSuccess: function (data) {
        return createAction(ActionTypes.FETCH_QUESTIONS_GROUP_SUCCESS, { data: data });
    },
    fetchQuestionsGroupFailure: function (error) {
        return createAction(ActionTypes.FETCH_QUESTIONS_GROUP_FAILURE, { error: error });
    },
};
