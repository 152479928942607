var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLeading, select, takeLatest, } from 'redux-saga/effects';
import { getActiveStoreJrniId } from '../location/stores';
import { getSelectedStoreServiceId } from '../location/selectedStore';
import { getQuestionId, generateQuestionsPayload, } from '../questions';
import { getSelectedDatetimeItemValue } from '../timeslot/timeAvailability';
import { getPersonalDetailsValues } from '../personalDetails';
import { actions as timeAvailabilityActions } from '../timeslot/timeAvailability';
import { runActionAfterDelay } from '../utils/sagas';
import { ERROR_409_MESSAGE } from '../constants';
import { ActionTypes, actions } from './actions';
import { addTimeslotToBasket, createBasket, deleteBasket, updateTimeslot, } from './requests';
import { getBasketAuthToken, getBasketId, getServiceItemId } from './selectors';
export function addToBasketSaga(_a) {
    var storeId, storeServiceId, datetime, basket, authToken, response, basketData, error_1;
    var onSuccess = _a.payload.onSuccess;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 8, , 12]);
                return [4 /*yield*/, select(getActiveStoreJrniId)];
            case 1:
                storeId = _b.sent();
                return [4 /*yield*/, select(getSelectedStoreServiceId)];
            case 2:
                storeServiceId = _b.sent();
                return [4 /*yield*/, select(getSelectedDatetimeItemValue)];
            case 3:
                datetime = _b.sent();
                return [4 /*yield*/, call(createBasket, storeId)];
            case 4:
                basket = _b.sent();
                authToken = basket.headers['auth-token'];
                return [4 /*yield*/, call(addTimeslotToBasket, basket.data.id, storeServiceId, datetime, authToken, storeId)];
            case 5:
                response = _b.sent();
                basketData = {
                    authToken: authToken,
                    eventId: response.data.id,
                    id: basket.data.id,
                };
                return [4 /*yield*/, put(actions.addToBasketSuccess(basketData))];
            case 6:
                _b.sent();
                return [4 /*yield*/, call(onSuccess)];
            case 7:
                _b.sent();
                return [3 /*break*/, 12];
            case 8:
                error_1 = _b.sent();
                if (!(error_1.message === ERROR_409_MESSAGE)) return [3 /*break*/, 10];
                return [4 /*yield*/, put(timeAvailabilityActions.loadTimeAvailability())];
            case 9:
                _b.sent();
                _b.label = 10;
            case 10: return [4 /*yield*/, put(actions.addToBasketFailure(error_1))];
            case 11:
                _b.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
export function updateBasketSaga() {
    var storeId, storeServiceId, questionId, serviceItemId, questionAnswer, datetime, questions, authToken, basketId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(getActiveStoreJrniId)];
            case 1:
                storeId = _a.sent();
                return [4 /*yield*/, select(getSelectedStoreServiceId)];
            case 2:
                storeServiceId = _a.sent();
                return [4 /*yield*/, select(getQuestionId)];
            case 3:
                questionId = _a.sent();
                return [4 /*yield*/, select(getServiceItemId)];
            case 4:
                serviceItemId = _a.sent();
                return [4 /*yield*/, select(getPersonalDetailsValues)];
            case 5:
                questionAnswer = (_a.sent()).questions;
                return [4 /*yield*/, select(getSelectedDatetimeItemValue)];
            case 6:
                datetime = _a.sent();
                return [4 /*yield*/, call(generateQuestionsPayload, questionId, questionAnswer)];
            case 7:
                questions = _a.sent();
                if (!questions)
                    return [2 /*return*/];
                return [4 /*yield*/, select(getBasketAuthToken)];
            case 8:
                authToken = _a.sent();
                return [4 /*yield*/, select(getBasketId)];
            case 9:
                basketId = _a.sent();
                return [4 /*yield*/, call(updateTimeslot, basketId, serviceItemId, storeServiceId, datetime, authToken, storeId, questions)];
            case 10:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function removeFromBasketSaga(_a) {
    var authToken, basketId, error_2;
    var onRedirect = _a.payload.onRedirect;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 6, , 9]);
                return [4 /*yield*/, select(getBasketAuthToken)];
            case 1:
                authToken = _b.sent();
                return [4 /*yield*/, select(getBasketId)];
            case 2:
                basketId = _b.sent();
                return [4 /*yield*/, call(deleteBasket, basketId, authToken)];
            case 3:
                _b.sent();
                return [4 /*yield*/, call(onRedirect)];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(actions.removeFromBasketSuccess())];
            case 5:
                _b.sent();
                return [3 /*break*/, 9];
            case 6:
                error_2 = _b.sent();
                return [4 /*yield*/, call(onRedirect)];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(actions.removeFromBasketFailure(error_2))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function sagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLeading(ActionTypes.ADD_TO_BASKET, addToBasketSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLeading(ActionTypes.REMOVE_FROM_BASKET, removeFromBasketSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(ActionTypes.ADD_TO_BASKET_FAILURE, function () {
                        return runActionAfterDelay(actions.resetBasketError());
                    })];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(ActionTypes.REMOVE_FROM_BASKET_FAILURE, function () {
                        return runActionAfterDelay(actions.resetBasketError());
                    })];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
