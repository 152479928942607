var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { takeLatest, call, select, put, } from 'redux-saga/effects';
import { actions as questionsActions } from '../questions';
import { updateBasketSaga } from '../basket/sagas';
import { getActiveStoreJrniId } from '../location/stores';
import { getPersonalDetailsValues } from '../personalDetails';
import { runActionAfterDelay } from '../utils/sagas';
import { getOPCO } from '../utils';
import { getBasketAuthToken, getBasket } from '../basket';
import { createJrniClient, checkoutJrniBasket } from './requests';
import { actions } from './actions';
import { getCountryFromTenant } from './utils';
import { ActionTypes, makeJrniClientPayload } from '.';
export function checkoutBasketSaga(_a) {
    var client, long_id, error_1;
    var onSuccess = _a.payload.onSuccess;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 9]);
                return [4 /*yield*/, call(updateBasketSaga)];
            case 1:
                _b.sent();
                return [4 /*yield*/, call(createClientSaga)];
            case 2:
                client = _b.sent();
                return [4 /*yield*/, call(checkoutBasket, client)];
            case 3:
                long_id = (_b.sent()).long_id;
                return [4 /*yield*/, call(onSuccess, long_id)];
            case 4:
                _b.sent();
                return [4 /*yield*/, put(actions.checkoutBasketSuccess({ long_id: long_id }))];
            case 5:
                _b.sent();
                return [4 /*yield*/, put(questionsActions.resetQuestions())];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7:
                error_1 = _b.sent();
                return [4 /*yield*/, put(actions.setError(error_1))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function createClientSaga() {
    var basketAuthToken, storeId, opco, _a, intlPhoneNumber, personalDetails, payload, response, error_2;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 9]);
                return [4 /*yield*/, select(getBasketAuthToken)];
            case 1:
                basketAuthToken = _b.sent();
                return [4 /*yield*/, select(getActiveStoreJrniId)];
            case 2:
                storeId = _b.sent();
                return [4 /*yield*/, select(getOPCO)];
            case 3:
                opco = _b.sent();
                return [4 /*yield*/, select(getPersonalDetailsValues)];
            case 4:
                _a = _b.sent(), intlPhoneNumber = _a.intlPhoneNumber, personalDetails = __rest(_a, ["intlPhoneNumber"]);
                return [4 /*yield*/, call(makeJrniClientPayload, __assign(__assign({}, personalDetails), { dialCode: intlPhoneNumber.country.dialCode, phoneNumber: intlPhoneNumber.phoneNumber, countryCode: intlPhoneNumber.country.iso2, country: getCountryFromTenant(opco), storeId: storeId }))];
            case 5:
                payload = _b.sent();
                return [4 /*yield*/, call(createJrniClient, storeId, basketAuthToken, payload)];
            case 6:
                response = _b.sent();
                return [2 /*return*/, response.data];
            case 7:
                error_2 = _b.sent();
                return [4 /*yield*/, put(actions.setError(error_2))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function checkoutBasket(client) {
    var basketAuthToken, basket, response, error_3;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(getBasketAuthToken)];
            case 1:
                basketAuthToken = _b.sent();
                return [4 /*yield*/, select(getBasket)];
            case 2:
                basket = _b.sent();
                return [4 /*yield*/, call(checkoutJrniBasket, client.id, basketAuthToken, (_a = basket) === null || _a === void 0 ? void 0 : _a.id)];
            case 3:
                response = _b.sent();
                return [2 /*return*/, response.data];
            case 4:
                error_3 = _b.sent();
                return [4 /*yield*/, put(actions.setError(error_3))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function sagas() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(ActionTypes.CHECKOUT_BASKET, checkoutBasketSaga)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(ActionTypes.SET_ERROR, function () {
                        return runActionAfterDelay(actions.clearError());
                    })];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
