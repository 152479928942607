var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jrniAPI } from '../../api/jrniAPI';
export var createBasket = function (companyId) {
    return jrniAPI.post("/baskets", {
        company_id: companyId,
    });
};
export var addTimeslotToBasket = function (basketId, serviceId, start, authToken, companyId) {
    return jrniAPI.post("/baskets/" + basketId + "/service_items", {
        service_id: serviceId,
        start: start,
        company_id: companyId,
    }, {
        headers: {
            'Auth-Token': authToken,
        },
    });
};
export var updateTimeslot = function (basketId, serviceItemId, serviceId, start, authToken, companyId, questions) {
    return jrniAPI.put("/baskets/" + basketId + "/service_items/" + serviceItemId, __assign({ service_id: serviceId, start: start, company_id: companyId }, questions), {
        headers: {
            'Auth-Token': authToken,
        },
    });
};
export var deleteBasket = function (basketId, authToken) {
    return jrniAPI.delete("/baskets/" + basketId, {
        headers: { 'auth-token': authToken },
    });
};
