import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { _t } from '@hip/translations';
import { Container, Grid, GridItem, Loading, MainContent, Toast, } from '@realm-kf/halcyon-components-react';
import { actions } from '../../core/categories';
import { actions as analyticsActions, AnalyticsEventMap, AnalyticsEvents, makeAnalyticsPayload, } from '../../core/analytics';
import { actions as questionsActions } from '../../core/questions';
import { Layout } from '../../components/layout';
import { CategoryButton } from '../../components/categoryButton/categoryButton';
import { getCategories, getCategoryError, getIsCategoriesLoading, } from '../../core/categories/selectors';
import { getCategory } from '../../core/utils';
import { useHeaderTranslations } from '../../hooks/useHeaderTranslations';
import { useBookingSteps } from '../../hooks/useBookingSteps';
import { CategoryStepAlert } from '../../components/categoryStepAlert/categoryStepAlert';
var CategoryStep = function () {
    var dispatch = useDispatch();
    var history = useHistory();
    var categories = useSelector(getCategories);
    var isCategoriesLoading = useSelector(getIsCategoriesLoading);
    var categoryError = useSelector(getCategoryError);
    var appointmentCategory = useSelector(getCategory);
    var nextStep = useBookingSteps().nextStep;
    var _a = useHeaderTranslations(), title = _a.title, subtitle = _a.subtitle;
    useEffect(function () {
        dispatch(questionsActions.resetQuestions());
        if (!categories)
            dispatch(actions.loadCategories());
        dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.START_BOOKING, appointmentCategory)));
    }, []);
    var handleSelectCategory = function (category) {
        dispatch(actions.selectCategory(category));
        dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.SELECT_SERVICE, category.label)));
        history.push(nextStep);
    };
    useEffect(function () {
        if (categoryError) {
            dispatch(analyticsActions.sendAnalyticsEvent(makeAnalyticsPayload(AnalyticsEvents.GENERIC_ERROR, AnalyticsEventMap[AnalyticsEvents.SELECT_SERVICE].action)));
        }
    }, [categoryError]);
    var alertContent = _t("appointment_booking.steps.category.alerts." + appointmentCategory);
    return (React.createElement(Layout, { title: title, subtitle: subtitle },
        React.createElement(MainContent, null,
            React.createElement(Container, null,
                alertContent && React.createElement(CategoryStepAlert, { content: alertContent }),
                categories && (React.createElement(Grid, { responsive: "alt", cols: 4 }, categories.map(function (category) { return (React.createElement(GridItem, { key: category.key },
                    React.createElement(CategoryButton, { category: category, onClick: function () { return handleSelectCategory(category); } }))); }))),
                React.createElement(Loading, { primary: true, show: isCategoriesLoading }),
                React.createElement(Toast, { content: _t('appointment_booking.common.sth_went_wrong'), fromTop: "0", isVisible: !!categoryError, variant: "warning" })))));
};
export { CategoryStep };
