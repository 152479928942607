import { createSelector } from 'reselect';
export var getRoot = function (state) {
    return state.basket;
};
export var getIsAddingToBasket = createSelector(getRoot, function (_a) {
    var isLoading = _a.isLoading;
    return isLoading;
});
export var getBasketError = createSelector(getRoot, function (_a) {
    var error = _a.error;
    return error;
});
export var getBasketErrorStatus = createSelector(getRoot, function (_a) {
    var _b;
    var error = _a.error;
    return (_b = error === null || error === void 0 ? void 0 : error.response) === null || _b === void 0 ? void 0 : _b.status;
});
export var getBasket = createSelector(getRoot, function (state) { return state === null || state === void 0 ? void 0 : state.data; });
export var getBasketAuthToken = createSelector(getRoot, function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.data) === null || _a === void 0 ? void 0 : _a.authToken; });
export var getBasketId = createSelector(getRoot, function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.data) === null || _a === void 0 ? void 0 : _a.id; });
export var getServiceItemId = createSelector(getRoot, function (state) { var _a; return (_a = state === null || state === void 0 ? void 0 : state.data) === null || _a === void 0 ? void 0 : _a.eventId; });
